import { Image, Button } from 'react-bootstrap';
import { useEffect, useState, useRef, act } from "react";
import emailjs from '@emailjs/browser';
import "./Franchise.css";

export default function Franchise() {
    const [activeDot, setActiveDot] = useState(1);
    const formRef = useRef<HTMLFormElement | null>(null);

    const handleSubmit = (e: any) => {
        console.log(e.target)
        e.preventDefault();
        emailjs.sendForm('service_gc10wur', 'template_hxpiewy', e.target, '7buVbTsIB_v1-R8Gu')
            .then((result) => {
                alert('Email gönderildi!');
                e.target.reset();
            }, (error) => {
                alert('Email gönderilirken hata oluştu!');
                e.target.reset();
            });
    };

    const handleButtonClick = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
    };

    return (
        <>
            <div>
                <h1>BOAC Soft Serve Franchise Fırsatları</h1>
                <p>BOAC Soft Serve ailesine katılarak siz de kendi işinizi kurma fırsatını yakalayın ve sektördeki yerinizi alın. Yüksek kaliteli <br></br>ürünlerimiz ve sağlam iş modelimizle, tatlı severlere unutulmaz deneyimler sunarak başarınıza başarı katın.</p>
            </div>
            <div className='franchise-bg-full-col'>
                <Image
                    className="franchise-bg"
                    src="https://boac-website.s3.eu-central-1.amazonaws.com/franchise_bg-1.png" />
            </div>
            <div className='franchise-bg-full-col'>
                <h1>Tedarik Zinciri</h1>
                <p>Tedarik zincirimiz tamamen firmamız kontrolünde olup, gerekli tüm topping'ler,<br></br> meyveler ve base'leri sağlamaktayız.</p>
            </div>
            <div className='franchise-bg-full-col'>
                <Image
                    className="franchise-bg"
                    src="https://boac-website.s3.eu-central-1.amazonaws.com/franchise-bg-2.png" />
            </div>
            <div className='franchise-bg-full-col'>
                <h1>Operasyonel İşleyiş ve Destek</h1>
                <p>Kolay kurulum ve operasyon sürecimiz, franchise sahiplerimizin hızlı bir başlangıç yapmalarını sağlar. Az personel ile<br></br> çalışabilen bu model, pratik ve etkili bir işleyiş sunar. Ayrıca, tedarik ve reklam desteğimizle markanızı güçlendirirken, olası<br></br> teknik aksaklıklara karşı hızlı çözüm sunma kapasitemizle kesintisiz bir operasyon sağlıyoruz.</p>
            </div>
            <div className='franchise-bg-full-form'>
                <Image
                    className="franchise-bg"
                    src="https://boac-website.s3.eu-central-1.amazonaws.com/franchise-bg-3.png" />
                <div style={{ backgroundColor: '#14213d' }}>
                    <h1 className='franchise-form-header'>
                        Franchise Talep Formu
                    </h1>
                    <div className='franchise-form-carousel'>
                        <div className={`${activeDot === 1 ? 'active' : ''}`} onClick={() => setActiveDot(1)}>
                            <Image
                                style={{ width: '20%' }}
                                src="https://boac-website.s3.eu-central-1.amazonaws.com/franchise_form_1.png" />
                            <p>Kişisel Ve Şirket <br></br> Bilgileri</p>
                        </div>
                        <div className={`${activeDot === 2 ? 'active' : ''}`} onClick={() => setActiveDot(2)}>
                            <Image
                                style={{ width: '20%' }}
                                src="https://boac-website.s3.eu-central-1.amazonaws.com/franchise_form_2.png" />
                            <p>Konum <br></br> Bilgisi</p>
                        </div>
                        <div className={`${activeDot === 3 ? 'active' : ''}`} onClick={() => setActiveDot(3)}>
                            <Image
                                style={{ width: '20%' }}
                                src="https://boac-website.s3.eu-central-1.amazonaws.com/franchise_form_3.png" />
                            <p>Yatırım <br></br> Bilgisi</p>
                        </div>
                        <div className={`${activeDot === 4 ? 'active' : ''}`} onClick={() => setActiveDot(4)}>
                            <Image
                                style={{ width: '20%' }}
                                src="https://boac-website.s3.eu-central-1.amazonaws.com/franchise_form_4.png" />
                            <p>Detaylı Bilgiler <br></br> Ve Ek Talepler</p>
                        </div>
                        <div className={`${activeDot === 5 ? 'active' : ''}`} onClick={() => setActiveDot(5)}>
                            <Image
                                style={{ width: '20%' }}
                                src="https://boac-website.s3.eu-central-1.amazonaws.com/franchise_form_5.png" />
                            <p>Gizlilik Ve Onay</p>
                        </div>
                    </div>
                    <div className='lines'>
                        <div className="line-container">
                            <hr className="horizontal-line" />
                            <div className="point point1">
                                {activeDot === 1 ? (
                                    <Image
                                        style={{ width: '20px' }}
                                        src="https://boac-website.s3.eu-central-1.amazonaws.com/franchise_form_dot.png" />
                                ) : (
                                    <span>•</span>
                                )}
                            </div>
                            <div className="point point2">
                                {activeDot === 2 ? (
                                    <Image
                                        style={{ width: '20px' }}
                                        src="https://boac-website.s3.eu-central-1.amazonaws.com/franchise_form_dot.png" />
                                ) : (
                                    <span>•</span>
                                )}
                            </div>
                            <div className="point point3">
                                {activeDot === 3 ? (
                                    <Image
                                        style={{ width: '20px' }}
                                        src="https://boac-website.s3.eu-central-1.amazonaws.com/franchise_form_dot.png" />
                                ) : (
                                    <span>•</span>
                                )}
                            </div>
                            <div className="point point4">
                                {activeDot === 4 ? (
                                    <Image
                                        style={{ width: '20px' }}
                                        src="https://boac-website.s3.eu-central-1.amazonaws.com/franchise_form_dot.png" />
                                ) : (
                                    <span>•</span>
                                )}
                            </div>
                            <div className="point point5">
                                {activeDot === 5 ? (
                                    <Image
                                        style={{ width: '20px' }}
                                        src="https://boac-website.s3.eu-central-1.amazonaws.com/franchise_form_dot.png" />
                                ) : (
                                    <span>•</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='franchise-form-row'>
                        <form ref={formRef} className='franchise-form' onSubmit={handleSubmit}>
                            <div className={`${activeDot === 1 ? 'visible' : 'invisible'}`}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ textAlign: 'left', paddingRight: '20px' }}>
                                        <label className="franchise-label" htmlFor="nameSurnameFrom">Ad Soyad:</label>
                                        <input className="franchise-input" type="text" name="name_surname_from" id="nameSurnameFrom"></input>
                                    </div>
                                    <div style={{ textAlign: 'left', paddingLeft: '50px' }}>
                                        <label className="franchise-label" htmlFor="companyNameFrom">Şirket Adı(varsa):</label>
                                        <input className="franchise-input" type="text" name="company_name_from" id="companyNameFrom"></input>
                                    </div>
                                </div>
                                <label style={{ marginTop: '10px' }} className="franchise-label" htmlFor="emailFrom">E-posta Adresi:</label>
                                <input className="franchise-input" type="text" name="email_from" id="emailFrom"></input>
                                <label style={{ marginTop: '10px' }} className="franchise-label" htmlFor="phoneFrom">Telefon Numarası:</label>
                                <input className="franchise-input" type="tel" name="phone_from" id="phoneFrom"></input>
                            </div>
                            <div className={`${activeDot === 2 ? 'visible' : 'invisible'}`}>
                                <label className="franchise-label" htmlFor="plannedLocationFrom">Planlanan Franchise Konumu:</label>
                                <input className="franchise-input" type="text" name="planned_location_from" id="plannedLocationFrom"></input>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                    <div style={{ textAlign: 'left', paddingRight: '20px' }}>
                                        <label className="franchise-label" htmlFor="CityFrom">Şehir:</label>
                                        <input className="franchise-input" type="text" name="city_from" id="CityFrom"></input>
                                    </div>
                                    <div style={{ textAlign: 'left', paddingLeft: '50px' }}>
                                        <label className="franchise-label" htmlFor="countryFrom">Ülke:</label>
                                        <input className="franchise-input" type="text" name="country_from" id="countryFrom"></input>
                                    </div>
                                </div>
                            </div>
                            <div className={`${activeDot === 3 ? 'visible' : 'invisible'}`}>
                                <label className="franchise-label" htmlFor="estimatedBudgetFrom">Tahmini Bütçe:</label>
                                <input className="franchise-input" type="text" name="estimated_budget_from" id="estimatedBudgetFrom"></input>
                                <label style={{ marginTop: '20px' }} className="franchise-label" htmlFor="capitalSourceFrom">Sermaye Kaynağı (Kendi kaynaklar, banka kredisi, yatırımcılar vb.):</label>
                                <input className="franchise-input" type="text" name="capital_source_from" id="capitalSourceFrom"></input>
                            </div>
                            <div className={`${activeDot === 4 ? 'visible' : 'invisible'}`}>
                                <label className="franchise-label" htmlFor="experienceFrom">Daha önce franchise işletme deneyiminiz var mı?:</label>
                                <div style={{ display: 'flex', marginTop: '10px', textAlign: 'left' }}>
                                    <input className="franchise-input" type="radio" name="experience" value="Evet" id="experienceFrom"></input>
                                    <label style={{ marginLeft: '10px' }} className="franchise-label" htmlFor="experienceFrom">Evet</label>
                                    <input style={{ marginLeft: '10px' }} className="franchise-input" type="radio" name="experience" value="Hayır" id="experienceFrom"></input>
                                    <label style={{ marginLeft: '10px' }} className="franchise-label" htmlFor="experienceFrom">Hayır</label>
                                </div>
                                <label style={{ marginTop: '20px' }} className="franchise-label" htmlFor="detailsFrom">BOAC Soft Serve ile ilgili daha fazla bilgi almak istediğiniz konular:</label>
                                <input style={{height:'100px'}} className="franchise-input" type="text" name="details_from" id="detailsFrom"></input>
                                <label style={{ marginTop: '20px' }} className="franchise-label" htmlFor="extraRequest">Ek talepleriniz veya özel istekleriniz:</label>
                                <input style={{height:'50px'}} className="franchise-input" type="text" name="extra_request" id="extraRequest"></input>
                            </div>
                            <div style={{textAlign: 'center', fontWeight: 'bold'}} className={`${activeDot === 5 ? 'visible' : 'invisible'}`}>
                                <p>Lütfen formdaki bilgilerinizi dikkatlice kontrol edin. Tüm bilgilerin doğruluğundan emin olun. Formu göndermek istediğinizden emin misiniz?</p>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                {activeDot === 5 ? (
                                    <Button className="franchise-submit-button" onClick={handleButtonClick}>Gönder</Button>
                                ) : (
                                    <Button className="franchise-submit-button" onClick={() => setActiveDot(activeDot + 1)}>Sıradaki</Button>
                                )}
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </>
    );
}